import { useCallback, useMemo, useRef, useState } from "react";

import useWindowSize from "hooks/use-window-size";

import classNames from "common/class-names";

import { AppClientsSectionDto } from "dto/components/pages/page-home/app-clients-section-dto";

import AppPagination from "components/app-pagination";

const AppClientsSection = ({ clientsData }: AppClientsSectionDto) => {
	const sizes = useWindowSize();
	const sliderRef = useRef<HTMLDivElement | null>(null);
	const [videoIndex, setVideoIndex] = useState<number>(0);
	const [videoKey, setVideoKey] = useState(Date.now());
	const isMobileView = useMemo(() => (sizes?.[0] <= 489 ? true : false), [sizes]);

	//prettier-ignore
	const clientSectionClassName = useMemo(() => (classNames({
		"clients-section": true,
		"clients-section--mobile": isMobileView
	})), [isMobileView]);

	const onHandlePlayBackVideo = useCallback(() => {
		let position = (clientsData.length - 1) * 581;

		if (videoIndex !== 0) {
			position = (videoIndex - 1) * 581;

			setVideoIndex((prev) => --prev);
		} else {
			setVideoIndex(clientsData.length - 1);
		}

		setVideoKey(Date.now());

		if (!sliderRef.current) return;

		sliderRef.current.scrollTo({ left: position, behavior: "smooth" });
	}, [clientsData.length, videoIndex]);

	//prettier-ignore
	const onHandlePlayNextVideo = useCallback((page?: number) => {
        let position = 0;

        if (videoIndex !== clientsData.length - 1) {
            position = (videoIndex + 1) * 581;
            
            setVideoIndex((prev) => ++prev);
        } else {
            setVideoIndex(0);
        }

        setVideoKey(Date.now());

        if (!sliderRef.current) return;

        sliderRef.current.scrollTo({ left: position, behavior: "smooth" });
    }, [videoIndex, clientsData.length]);

	const onHandleSwitchVideo = useCallback((index: number) => {
		const position = index * 581;

		setVideoIndex(index);

		setVideoKey(Date.now());

		if (!sliderRef.current) return;

		sliderRef.current.scrollTo({ left: position, behavior: "smooth" });
	}, []);

	const getYouTubeEmbedUrl = (url?: string) => {
		let videoId = undefined;

		if (!url) return undefined;

		if (url.includes("v=")) {
			videoId = url.split("v=")[1]?.split("&")[0];
		} else if (url.includes("youtu.be/")) {
			videoId = url.split("youtu.be/")[1];
		}

		if (!videoId) {
			return undefined;
		}

		return `https://www.youtube-nocookie.com/embed/${videoId}?disablekb=1&controls=0&showinfo=0&modestbranding=0&rel=0&playsinline=1&enablejsapi=1&&autoplay=1&loop=1&mute=1&playlist=${videoId}`;
	};

	const onHandleNext = useCallback(() => {
		onHandlePlayNextVideo();
	}, [onHandlePlayNextVideo]);

	const onHandleBack = useCallback(() => {
		onHandlePlayBackVideo();
	}, [onHandlePlayBackVideo]);

	return (
		<div className="app-clients-section">
			<div className={clientSectionClassName}>
				<div className="clients-section__body">
					<div className="clients-section__wrapper">
						<div>
							<div key={clientsData[videoIndex].id}>
								<p className="clients-section__sub-title">{clientsData[videoIndex].title}</p>

								<p className="clients-section__description">{clientsData[videoIndex].descriptionShort}</p>
							</div>
						</div>

						{!isMobileView && (
							<AppPagination
								totalPageNumber={clientsData.length}
								currentPage={videoIndex}
								isLoop={true}
								onAuto={(page: number) => onHandlePlayNextVideo(page)}
								onHandleNext={onHandleNext}
								onHandleBack={onHandleBack}
							/>
						)}
					</div>

					<div className="clients-section__youtube">
						{clientsData[videoIndex].cover.mediaType === "VIDEO" ? (
							<iframe
								key={videoKey}
								width="100%"
								height="720px"
								src={getYouTubeEmbedUrl(clientsData[videoIndex].cover.backgroundURL)}
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								title={`YouTube video ${videoIndex}`}
							/>
						) : (
							<div className="clients-section__image-wrapper">
								<img className="clients-section__image-background" src={clientsData[videoIndex].cover?.backgroundURL} alt={clientsData[videoIndex].title} />
								<img className="clients-section__image" src={clientsData[videoIndex].cover.imageURL} alt={clientsData[videoIndex].title} />
							</div>
						)}
					</div>

					{!isMobileView && (
						<div className="clients-section__container">
							<div className="clients-section__slider-container">
								<div className="slider" ref={sliderRef}>
									{clientsData.map((o, i) => {
										const imageClassName = classNames({ slider__image: true, "slider__image--active": i === videoIndex });
										const isLastItem = i === clientsData.length - 1;
										const marginRight = isLastItem ? `calc(96vw - 591px)` : "0";

										return (
											<div className="slider__item" key={i} style={{ marginRight }} onClick={() => onHandleSwitchVideo(i)}>
												<div className={imageClassName} style={{ backgroundImage: `url(${o.cover.thumbnailURL})` }} />

												<div className="slider__wrapper">
													<p className="slider__title">{o.title}</p>

													<p className="slider__text">{o.projectType}</p>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					)}

					{isMobileView && (
						<div className="preview">
							<div className="preview__container">
								{clientsData.map((o, i) => {
									if (i !== videoIndex) return null;

									return (
										<div className="preview__item" key={i}>
											<div className="preview__item-left">
												<div className="preview__image" style={{ backgroundImage: `url(${o.cover.thumbnailURL})` }} />

												<div className="preview__info-wrapper">
													<p className="preview__info-title">{o.title}</p>

													<p className="preview__info-text">{o.projectType}</p>
												</div>
											</div>

											<div className="mobile-pagination">
												<AppPagination
													totalPageNumber={clientsData.length}
													currentPage={videoIndex}
													isLoop={true}
													onAuto={(page: number) => onHandlePlayNextVideo(page)}
													onHandleNext={onHandleNext}
													shortHandRightIcon={true}
												/>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default AppClientsSection;

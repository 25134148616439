import { forwardRef, memo, useImperativeHandle, useState } from "react";
import { Tab, Tabs } from "@mui/material";

import { ContentTab, ContentTabsProps, ImperativeHandleContentTabDto } from "dto/components/app-content-tab-dto";

const AppContentTab = ({ tabs, onSelect }: ContentTabsProps, ref: React.ForwardedRef<ImperativeHandleContentTabDto>) => {
	const [selectedTab, setSelectedTab] = useState<number>(0);

	const selectedIndex = tabs.findIndex((tab) => tab.indexKey === selectedTab);

	const onHandleTabSelect = (tab: ContentTab) => {
		setSelectedTab(tab.indexKey || 0);
		if (onSelect) onSelect(tab);
	};

	useImperativeHandle(ref, () => ({
		selectedDetails: tabs?.filter((tab) => tab.indexKey === selectedTab) || [],
	}));

	return (
		<Tabs
			value={selectedIndex}
			className="content-tabs"
			TabIndicatorProps={{
				style: {
					display: "none",
				},
			}}
			sx={{
				"& .MuiTabs-indicator": {
					display: "none",
				},
				"& .MuiTab-root": {
					borderRadius: "6px",
					margin: "0 5px",
					color: "var(--theme-desc-color)",
					textTransform: "none",
					"@media (max-width: 489px)": {
						margin: "0",
					},
				},
				"& .Mui-selected": {
					backgroundColor: selectedIndex === 0 ? "var(--theme-highlight)" : "var(--c-pagination-highlight-2)",
					color: "var(--c-white) !important",
				},
				"& .MuiTabs-flexContainer": {
					padding: "16px 12px",
					justifyContent: "center",
					border: "1px solid var(--c-dark-grey)",
					borderRadius: "6px",
					width: "auto",
					"@media (max-width: 320px)": {
						padding: "10px 6px",
					},
				},
			}}
		>
			{tabs.map((tab) => (
				<Tab
					key={tab.indexKey}
					label={tab.title}
					onClick={() => onHandleTabSelect(tab)}
					className="tab"
					sx={{
						color: tab.indexKey === selectedTab ? "var(--c-white)" : "inherit",
					}}
				/>
			))}
		</Tabs>
	);
};

export default memo(forwardRef(AppContentTab));

import { useCallback, useEffect } from "react";

import leftArrowIcon from "assets/images/components/app-pagination/arrow-left-icon.svg";
import rightArrowIcon from "assets/images/components/app-pagination/arrow-right-icon.svg";
import rightArrowShorthandIcon from "assets/images/components/app-pagination/arrow-right-shorthand-icon.svg";

const AppPagination = ({ totalPageNumber, currentPage, onHandleBack, onHandleNext, onAuto, isLoop, shortHandRightIcon }: any) => {
	const onHandleRenderStepper = useCallback(() => {
		const content = [];
		for (let i = 0; i < totalPageNumber; ++i) {
			if (i === currentPage) {
				content.push(<div className="stepper--active" key={i} />);
			} else {
				content.push(<div className="stepper" key={i} />);
			}
		}
		return content;
	}, [currentPage, totalPageNumber]);

	useEffect(() => {
		let timeoutId: NodeJS.Timeout | null = null;

		if (isLoop && totalPageNumber > 1) {
			timeoutId = setTimeout(() => {
				if (currentPage + 1 !== totalPageNumber) {
					onAuto(currentPage + 1);
				} else {
					onAuto(0);
				}
			}, 5000);
		}

		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, [isLoop, currentPage, totalPageNumber, onHandleNext, onAuto]);

	return (
		<div className="app-pagination">
			<div className="pagination">
				{onHandleBack && (
					<div className="pagination__container" onClick={onHandleBack}>
						<img className="pagination__icon" src={leftArrowIcon} alt={"leftArrowIcon"} />
					</div>
				)}

				<div className="pagination__wrapper">{onHandleRenderStepper()}</div>

				<div className="pagination__container" onClick={onHandleNext}>
					<img className="pagination__icon" src={shortHandRightIcon ? rightArrowShorthandIcon : rightArrowIcon} alt={"rightArrowIcon"} />
				</div>
			</div>
		</div>
	);
};
export default AppPagination;

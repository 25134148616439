import AppBrochureCardDto from "dto/components/app-brochure-card-dto";

import AppButton from "components/app-button";

const AppBrochureCard = ({ title, image, link }: AppBrochureCardDto) => {
	const handleClick = () => {
		window.open(link, "_blank");
	};

	const handlePreview = () => {
		window.open(`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(link)}`, "_blank");
	};

	return (
		<div className="app-brochure-card">
			<div className="brochure-card">
				<div className="brochure-card__container" onClick={handlePreview}>
					<div className="brochure-card__image" style={{ backgroundImage: `url(${image})` }} />
				</div>

				{/*prettier-ignore*/}
				<p className="brochure-card__title" title={title}>{title}</p>

				<AppButton className="brochure-card__btn" label="Download E-Brochure" onClick={handleClick} tertiary />
			</div>
		</div>
	);
};

export default AppBrochureCard;
